import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { SessionContext } from "../SessionContext.tsx";
import { ProgressiveRequest } from "../ProgressiveRequest.tsx";
import { getServer } from '../Server.tsx';
import IconChevronLeft from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-left.tsx";
import IconAntennaBars4 from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/antenna-bars-4.tsx";
import { ResponsiveContainer, XAxis, Legend, CartesianGrid, YAxis, Tooltip,
	PieChart, Pie, Sector, Cell,
	BarChart, Bar,
	AreaChart, Area,
	LineChart, Line,
	Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis,
	ComposedChart,
} from 'recharts';
//import * as XLSX from 'https://deno.land/x/sheetjs@v0.18.0+deno/xlsx.mjs';

export function KPIContent({ back, args }){

	return (
		<div>
			<div class="d-flex align-items-center mt-2 mb-4">
				{back?
					<div class="p-1 rounded-pill text-white me-4 arrowb" onClick={back}>
						<IconChevronLeft class="w-6 h-6" />
					</div>:
					<a class="p-1 rounded-pill text-white me-4 arrowb" href="/">
						<IconChevronLeft class="w-6 h-6" />
					</a>
				}
				<h1 class="d-flex align-items-center h3 mb-0">
					<IconAntennaBars4 class="w-6 h-6" /><span class="ms-2">Les indicateurs</span>
				</h1>
			</div>
			<_KPIContent args={args}/>
		</div>
	);
}

class _KPIContent extends Component {
	static contextType = SessionContext;
	
	state = {
		loading: true,
		items: null
	};

	_state = {
		loading: true,
		info: {},
		selectedItem: null
	};

	customSetState(o){
		this.setState(o);
		let nwState = {};
		for(let prop in this._state){
			nwState[prop] = this._state[prop];
		}
		for(let prop in o){
			nwState[prop] = o[prop];
		}
		this._state = nwState;
	}
	
	componentDidMount() {
		let that = this;

		this.load();
	}

	isLoading = false;
	load(changeMod){
		let that = this;
		let session = this.context;

		if(this.isLoading){
			return;
		}

		this.isLoading = true;
		ProgressiveRequest({
			url: getServer()+'/api/front/kpi',
			method: 'post',
			data: {
				action: 'content',
				session: session,
				reload: changeMod || null,
			},
			receiving: function(data){

				console.log('receiving');
				console.log(data);

				if(data.init){
					that.customSetState({
						items: data.items
					});
				}else{
					let nwItems = {};
					for(let prop in that._state.items){
						nwItems[prop] = that._state.items[prop];
					}
					for(let prop in data.items){
						nwItems[prop] = data.items[prop];
					}
					that.customSetState({
						items: nwItems
					});
				}
			},
			received: function(){
				console.log('done');
				that.isLoading = false;
			}
		});
	}

	//https://deno.land/x/sheetjs@v0.18.0%2Bdeno/README.md?source
	downloadSheet = function (title, data) {

		return;
		//Create a new blank workbook
		let wb = XLSX.utils.book_new();

		//Write title
		if(!wb.Props) wb.Props = {};
		wb.Props.Title = title;

		//Fix content: remove graph colors
		data.forEach(function(v){ delete v.fill });

		//Write content
		const worksheet = XLSX.utils.json_to_sheet(data);
		XLSX.utils.book_append_sheet(wb, worksheet, "Feuille 1");

		//Fix headers: Capitalize headers
		XLSX.utils.sheet_add_aoa(worksheet, [Object.keys(data[0]).map(e=>(e.charAt(0).toUpperCase() + e.slice(1)).replaceAll('_', ' '))], { origin: "A1" });

		//Add formulas
		//worksheet['C1'] = { t:'n', f: "SUM(A1:A3*B1:B3)", F:"C1:C1" };

		//Download file
		XLSX.writeFile(wb, title + ".xlsx");
	}

	downloadBestProductsProgression = function (title, data) {
		let ndata = [];
		//Flattening data and correcting names
		data.map(e=> ndata.push({
			id_product: e.id_product,
			product: e.product,
			ca_avant: e.ca.before,
			ca_apres: e.ca.after,
			nb_avant: e.nb.before,
			nb_apres: e.nb.after,
			progression: e.progression,
		}));
		this.downloadSheet(title, ndata);
	}

	downloadCAPerProducts = function (title, data) {
		let ndata = [];
		//Flattening data and correcting names
		data.map(e=> ndata.push({
			id_type: e.id_type,
			type: e.type,
			id_produit: e.id_product,
			produit: e.product,
			nb: e.nb,
			CA: e.ca,
			nom: e.name,
			pourcentage: e.prct,
		}));
		this.downloadSheet(title, ndata);
	}

	displayContent = function(item){
		this.setState({content: item});
	}

	radioChanged = function(event){
		let that = this;
		that.load({type: event.target.name, period: {name: event.target.name, value: event.target.value}});
	}
	
	render() {
		let that = this;

		const radarData = [
			{
			  categorie: '0-5€',
			  cash: 1203,
			  CB: 0,
			},
			{
			  categorie: '5-10€',
			  cash: 1042,
			  CB: 58,
			},
			{
			  categorie: '10-15€',
			  cash: 709,
			  CB: 1829,
			},
			{
			  categorie: '15-20€',
			  cash: 433,
			  CB: 630,
			},
			{
			  categorie: '20-30€',
			  cash: 230,
			  CB: 727,
			},
			{
			  categorie: '30-45€',
			  cash: 122,
			  CB: 305,
			},
			{
				categorie: '+45€',
				cash: 208,
				CB: 245,
			  },
		  ];

		  const composedData = [
			{
			  name: '8h',
			  avg: 140,
			  total: 120,
			  tabac: 30,
			  jeu: 90,
			  bar: 0,
			},
			{
				name: '9h',
				avg: 340,
				total: 245,
				tabac: 50,
				jeu: 195,
				bar: 0,
			  },
			  {
				name: '10h',
				avg: 180,
				total: 160,
				tabac: 40,
				jeu: 120,
				bar: 0,
			  },
			  {
				name: '11h',
				avg: 100,
				total: 200,
				tabac: 70,
				jeu: 30,
				bar: 100,
			  },
			  {
				name: '12h',
				avg: 260,
				total: 260,
				tabac: 100,
				jeu: 60,
				bar: 100,
			  },
			  {
				name: '13h',
				avg: 310,
				total: 330,
				tabac: 120,
				jeu: 40,
				bar: 170,
			  },
			  {
				name: '14h',
				avg: 320,
				total: 320,
				tabac: 160,
				jeu: 45,
				bar: 115,
			  },
			  {
				name: '15h',
				avg: 130,
				total: 130,
				tabac: 100,
				jeu: 30,
				bar: 0,
			  },
			  {
				name: '16h',
				avg: 80,
				total: 70,
				tabac: 50,
				jeu: 20,
				bar: 0,
			  },
			  {
				name: '17h',
				avg: 240,
				total: 240,
				tabac: 170,
				jeu: 70,
				bar: 0,
			  },
			  {
				name: '18h',
				avg: 300,
				total: 200,
				tabac: 100,
				jeu: 60,
				bar: 140,
			  },
			  {
				name: '19h',
				avg: 320,
				total: 320,
				tabac: 90,
				jeu: 90,
				bar: 140,
			  },
		  ];

		let renderLabel = function(entry){
			//Display only labels for elements of at least 3%
			if('prct' in entry){
				return entry.prct>3?entry.name:null;
			}
			return entry.name;
		};

		let renderColor = function(entry) {
			return entry.fill;
		};

		let tickFormatter = (value: string, index: number) => {
			const limit = 15;
			if (value.length < limit) return value;
			return `${value.substring(0, limit)}...`;
		 };

		const RADIAN = Math.PI / 180;
    	const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
 
        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${that._state.items.kpiCaPerProduct.data.categories[index].name}`}
            </text>
        );
    };

	//@doc https://recharts.org/en-US/api/Legend
	const renderLegend = (props) => {
		const { payload } = props;

		//Display legends only for the first pie
		let flegends = [];
		payload.forEach(element => {
			let dev = that._state.items.kpiCaPerProduct.data.categories.find(e => e.name == element.value);
			if (dev) flegends.push(element);
		});
	  
		return (
		  <ul>
			{
			  flegends.map((entry, index) => (
				<li key={`litem-${index}`} class={`recharts-legend-item legend-item-${index}`} style="display: inline-block; margin-right: 10px;"><svg width="14" height="14" viewBox="0 0 32 32" class="recharts-surface" style="display: inline-block; vertical-align: middle; margin-right: 4px;"><title></title><desc></desc><path stroke="none" fill={entry.color} d="M0,4h32v24h-32z" class="recharts-legend-icon"></path></svg><span class="recharts-legend-item-text" style={`color: ${entry.color}`}>{entry.value}</span></li>
			  ))
			}
		  </ul>
		);
	  }
		
		if(this._state.items){
			
			return (
				<div>
					<div class="my-4 py-4">
						<div class="row my-3">

						{that._state.items.kpiPrices?
							(that._state.items.kpiPrices.data?
								<div class="my-2">
										<div class="card shadow">
											<div class="card-body">
												<div>
													<div class="row mb-4">
														<div class="col">
															<div>
																<ResponsiveContainer width={380} height={300}>
																	<RadarChart cx="50%" cy="50%" outerRadius="80%" data={radarData}>
																		<PolarGrid />
																		<PolarAngleAxis dataKey="categorie" style={{fontSize: '0.8rem'}} />
																		<PolarRadiusAxis angle={30} domain={[0, 150]} />
																		<Radar name="Cash" dataKey="cash" stroke="rgb(40 46 135)" fill="rgb(40 46 135)" fillOpacity={0.6} />
																		<Radar name="CB" dataKey="CB" stroke="#009fe3" fill="#009fe3" fillOpacity={0.6} />
																		<Legend />
																		<Tooltip />
																	</RadarChart>
																</ResponsiveContainer>
															</div>
														</div>
														<div class="col">
															<div class="h4 titleb"><img src="icons/Transactions_Historique_Paiements_Facture.svg" class="d-inline-block me-1" style="width: 20px;" />
															Moyens de paiement par montant</div>
															<div class="mt-2 mb-4">Visualisez en un coup d'oeil le profil de paiement par intervalle de valeur et optimisez vos seuils de carte et vos commissions bançaires.</div>
															<div class="d-flex">
																<a type="button" href="#" onClick={() => this.downloadSheet("Moyens de paiement par montant", radarData)} style="background: rgb(41, 45, 135);" class="btn text-white border-0 rounded-pill d-flex align-items-center btn-sm ps-3">Télécharger <i class="fa-regular fa-circle-down ms-2"></i></a>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									:<div>
										<div class="spinner-border text-secondary" role="status">
											<span class="visually-hidden">Loading...</span>
										</div>
									</div>)
								:null
							}

							{that._state.items.kpiHours?
								(that._state.items.kpiHours.data?
									<div class="my-2">
										<div class="card shadow">
											<div class="card-body">
												<div>
													<div class="row mb-4">
														<div class="col">
															<div class="h4 titleb">
																<img src="icons/Transactions_Historique_Paiements_Facture.svg" class="d-inline-block me-1" style="width: 20px;" />
																Ventes par heure
															</div>
															<div class="mt-2 mb-4">Suivez la progression du volume de ventes en valeur heure par heure. Identifiez les goulets d'étranglement et les optimisations possibles dans l'organisation de votre établissement.</div>
															<div class="d-flex">
																<a type="button" href="#" onClick={() => this.downloadSheet("Ventes par heure", composedData)} style="background: rgb(41, 45, 135);" class="btn text-white border-0 rounded-pill d-flex align-items-center btn-sm ps-3">Télécharger <i class="fa-regular fa-circle-down ms-2"></i></a>
															</div>
														</div>
														<div class="col">
															<div>
																<ResponsiveContainer width={435} height={335}>
																	<ComposedChart
																		width={500}
																		height={400}
																		data={composedData}
																		margin={{
																			top: 20,
																			right: 20,
																			bottom: 20,
																			left: 20,
																		}}>
																		<XAxis dataKey="name" scale="band" axisLine={false} tickLine={false} style={{fontSize: '0.8rem'}} />
																		<Tooltip />
																		<Bar dataKey="avg" barSize={20} fill="#413ea0" radius={[10, 10, 0, 0]} />
																		<Line type="monotone" dataKey="total" stroke="#0088FE" strokeWidth={2} dot={false} />
																		<Line type="monotone" dataKey="tabac" stroke="#00C49F" strokeWidth={2} dot={false} />
																		<Line type="monotone" dataKey="jeu" stroke="#FFBB28" strokeWidth={2} dot={false} />
																		<Line type="monotone" dataKey="bar" stroke="#FF8042" strokeWidth={2} dot={false} />
																	</ComposedChart>
																</ResponsiveContainer>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									:<div>
										<div class="spinner-border text-secondary" role="status">
											<span class="visually-hidden">Loading...</span>
										</div>
									</div>)
								:null
							}
							
							{that._state.items.kpiCaPerProduct?
								(that._state.items.kpiCaPerProduct.data?
									<div class="my-2">
										<div class="card shadow">
											<div class="card-body">
												<div>
													<div class="row mb-4">
														<div class="col">
															<div>
																<ResponsiveContainer width={435} height={335}>
																	<PieChart>
																		<Pie data={that._state.items.kpiCaPerProduct.data.categories} dataKey="value" cx="50%" cy="50%" outerRadius={90} fill="#8884d8" />
																		<Pie data={that._state.items.kpiCaPerProduct.data.products} dataKey="ca" cx="50%" cy="50%" innerRadius={100} outerRadius={120} fill="#82ca9d" label={renderLabel} labelLine={false} style={{fontSize: '0.8rem'}} />
																		<Tooltip />
																		<Legend content={renderLegend} />
																	</PieChart>
																</ResponsiveContainer>
															</div>
														</div>
														<div class="col">
															<div class="h4 titleb"><img src="icons/Transactions_Historique_Paiements_Facture.svg" class="d-inline-block me-1" style="width: 20px;" />
															Chiffres par produits</div>
															<div class="mt-2 mb-4">Voici la structure du chiffre d'affaires par produit. Suivez l'importance de ventes consolidées par famille/catégorie et par produit et leur évolution.</div>
															<div class="d-flex">
																<a type="button" href="#" onClick={() => this.downloadCAPerProducts("Chiffres par produits", that._state.items.kpiCaPerProduct.data.products)} style="background: rgb(41, 45, 135);" class="btn text-white border-0 rounded-pill d-flex align-items-center btn-sm ps-3">Télécharger <i class="fa-regular fa-circle-down ms-2"></i></a>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									:<div>
										<div class="spinner-border text-secondary" role="status">
											<span class="visually-hidden">Loading...</span>
										</div>
									</div>)
								:null
							}
								
							{that._state.items.kpiCaPerPaymentType?
								(that._state.items.kpiCaPerPaymentType.data?
									<div class="my-2">
										<div class="card shadow">
											<div class="card-body">
												<div>
													<div class="row mb-4">
														<div class="col">
															<div class="h4 titleb"><img src="icons/Transactions_Historique_Paiements_Facture.svg" class="d-inline-block me-1" style="width: 20px;" />
															Moyens de paiement</div>
															<div class="mt-2 mb-2">Voici le classement des moyens de paiement ordonné par importance du volume d'affaires sur la période sélectionnée.</div>
															<div class="mb-2 mb-4">
																<div class="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group"  onChange={that.radioChanged.bind(that)}>
																	<input type="radio" class="btn-check" name="kpiCaPerPaymentType" id="paymentT_1" value="7d" autocomplete="off" checked={that._state.items.kpiCaPerPaymentType.data.mod == '7d'} />
																	<label class="btn btn-outline-secondary px-3" for="paymentT_1">7j</label>

																	<input type="radio" class="btn-check" name="kpiCaPerPaymentType" id="paymentT_2" value="14d" autocomplete="off" checked={that._state.items.kpiCaPerPaymentType.data.mod == '14d'} />
																	<label class="btn btn-outline-secondary px-3" for="paymentT_2">14j</label>

																	<input type="radio" class="btn-check" name="kpiCaPerPaymentType" id="paymentT_3" value="30d" autocomplete="off" checked={that._state.items.kpiCaPerPaymentType.data.mod == '30d'} />
																	<label class="btn btn-outline-secondary px-3" for="paymentT_3">30j</label>
																</div>
															</div>
															<div class="d-flex">
																<a type="button" href="#" onClick={() => this.downloadSheet("Moyens de paiement", that._state.items.kpiCaPerPaymentType.data.products)} style="background: rgb(41, 45, 135);" class="btn text-white border-0 rounded-pill d-flex align-items-center btn-sm ps-3">Télécharger <i class="fa-regular fa-circle-down ms-2"></i></a>
															</div>
														</div>
														<div class="col">
															<div width="350px" height="250px">
																<PieChart width={350} height={250}>
																	<Pie
																		data={that._state.items.kpiCaPerPaymentType.data.products}
																		innerRadius={80}
																		outerRadius={100}
																		fill="#8884d8"
																		paddingAngle={0}
																		dataKey="ca"
																		label={renderLabel}
																		cx="55%" cy="50%"
																		style={{fontSize: '0.8rem'}}
																		>
																	</Pie>
																</PieChart>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									:<div>
										<div class="spinner-border text-secondary" role="status">
											<span class="visually-hidden">Loading...</span>
										</div>
									</div>)
								:null
							}
								
							{that._state.items.kpiForecast?
								(that._state.items.kpiForecast.data?
									<div class="my-2">
										<div class="card shadow">
											<div class="card-body">
												<div>
													<div class="row mb-4">
														<div class="col-4">
															<div class="h4 titleb"><img src="icons/Actions_Piloter_Activites.svg" class="d-inline-block me-1" style="width: 20px;" />
															Les prévisions de votre activité</div>
															<div class="mt-2 mb-4">Les prévisions sont calculées en fonction de la variation du volume d'affaires moyen.</div>
															<div class="d-flex">
																<a type="button" href="#" onClick={() => this.downloadSheet("Les prévisions de votre activité", that._state.items.kpiForecast.data.products)} style="background: rgb(41, 45, 135);" class="btn text-white border-0 rounded-pill d-flex align-items-center btn-sm ps-3">Télécharger <i class="fa-regular fa-circle-down ms-2"></i></a></div>
														</div>
														<div class="col">
															<div width="450px" height="220px" style="position:relative;height: 100%">
																{that._state.items.kpiForecast.data.products.length > 0 ?
																<BarChart width={450} height={220} data={that._state.items.kpiForecast.data.products}>
																	<Bar dataKey="ca" fill={renderColor} radius={[10, 10, 0, 0]} />
																	<XAxis dataKey="date" axisLine={false} tickLine={false} style={{fontSize: '0.8rem'}} />
																	<Tooltip  cursor={{ fill: "rgb(204 204 204 / 75%)" }} />
																</BarChart>
																
																: <p style="top: 50%; left: 50%; transform: translate(-50%, -50%); position: absolute;" >Pas assez de données disponibles</p>
																}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									:<div>
										<div class="spinner-border text-secondary" role="status">
											<span class="visually-hidden">Loading...</span>
										</div>
									</div>)
								:null
							}
							
							{that._state.items.kpiProductProgression?
								(that._state.items.kpiProductProgression.data?
									<div class="my-2">
										<div class="card shadow">
											<div class="card-body">
												<div>
													<div class="row mb-4">
														<div class="col-4">
															<div class="h4 titleb"><img src="icons/Actions_Piloter_Activites.svg" class="d-inline-block me-1" style="width: 20px;" />
															Les plus belles progressions et régressions</div>
															<div class="mt-2 mb-2">Les changements sont calculés en fonction de la variation du volume d'affaires par produit.</div>
															<div class="mb-2 mb-4">
																<div class="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group" onChange={that.radioChanged.bind(that)}>
																	<input type="radio" class="btn-check" name="kpiProductProgression" id="progressions_1" autocomplete="off" value="7d" checked={that._state.items.kpiProductProgression.data.mod == '7d'} />
																	<label class="btn btn-outline-secondary px-3" for="progressions_1">7j</label>

																	<input type="radio" class="btn-check" name="kpiProductProgression" id="progressions_2" autocomplete="off" value="14d" checked={that._state.items.kpiProductProgression.data.mod == '14d'} />
																	<label class="btn btn-outline-secondary px-3" for="progressions_2">14j</label>

																	<input type="radio" class="btn-check" name="kpiProductProgression" id="progressions_3" autocomplete="off" value="30d" checked={that._state.items.kpiProductProgression.data.mod == '30d'} />
																	<label class="btn btn-outline-secondary px-3" for="progressions_3">30j</label>
																</div>
															</div>
															<div class="d-flex">
																<a type="button" href="#" onClick={() => this.downloadBestProductsProgression("Les plus belles progressions et régressions", that._state.items.kpiProductProgression.data.products)} style="background: rgb(41, 45, 135);" class="btn text-white border-0 rounded-pill d-flex align-items-center btn-sm ps-3">Télécharger <i class="fa-regular fa-circle-down ms-2"></i></a>
															</div>
														</div>
														<div class="col row">
														{that._state.items.kpiProductProgression.data.products.map((p)=>{
															return <div class="col m-1 card shadow">
																<div class="card-body">
																	<h4 class="txtover">{p.product}</h4>
																	<p style={"color:"+(p.progression > 0 ? 'green' : 'red')}>{p.progression > 0 ? '📈+' : '📉'}
																	{Math.floor(p.progression)}%</p>
																	<div>
																		<div class="mb-1 text-center" style="background: rgba(195, 195, 195, 0.29); border-radius: 7%; padding: 3px; font-size: 14px;">Mois dernier<br />{p.ca.before}€</div>
																		<div class="mb-1 text-center" style="background: rgba(195, 195, 195, 0.29); border-radius: 7%; padding: 3px; font-size: 14px;">Ce mois-ci<br />{p.ca.after}€</div>
																		<div class="mb-1 text-center" style={"background: rgb("+(p.progression > 0 ? '1 176 69' : '255 0 0')+" / 29%); border-radius: 7%; padding: 3px; font-size: 14px;"}>{p.progression > 0 ? '+' : '-'} {Math.abs(p.ca.after - p.ca.before).toFixed(2)}€</div>
																	</div>
																</div>
															</div>;
														})}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									:<div>
										<div class="spinner-border text-secondary" role="status">
											<span class="visually-hidden">Loading...</span>
										</div>
									</div>)
								:null
							}
							
							{that._state.items.kpiTopProducts?
								(that._state.items.kpiTopProducts.data?
									<div class="my-2">
										<div class="card shadow">
											<div class="card-body">
												<div>
													<div class="row mb-4">
														<div class="col">
															<div class="h4 titleb"><img src="icons/Actions_Piloter_Activites.svg" class="d-inline-block me-1" style="width: 20px;" />
															Les Best Sellers</div>
															<div class="mt-2 mb-2">Découvrez les produits les plus vendus sur la période sélectionnée classés par ordre d'importance du volume d'affaires.</div>
															<div class="mb-2 mb-4">
																<div class="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group" onChange={that.radioChanged.bind(that)}>
																	<input type="radio" class="btn-check" name="kpiTopProducts" id="bestseller_1" autocomplete="off" value="7d"  checked={that._state.items.kpiTopProducts.data.mod == '7d'} />
																	<label class="btn btn-outline-secondary px-3" for="bestseller_1">7j</label>

																	<input type="radio" class="btn-check" name="kpiTopProducts" id="bestseller_2" autocomplete="off" value="14d" checked={that._state.items.kpiTopProducts.data.mod == '14d'} />
																	<label class="btn btn-outline-secondary px-3" for="bestseller_2">14j</label>

																	<input type="radio" class="btn-check" name="kpiTopProducts" id="bestseller_3" autocomplete="off" value="30d" checked={that._state.items.kpiTopProducts.data.mod == '30d'} />
																	<label class="btn btn-outline-secondary px-3" for="bestseller_3">30j</label>
																</div>
															</div>
															<div class="d-flex">
																<a type="button" href="#" onClick={() => this.downloadSheet("Les Best Sellers", that._state.items.kpiTopProducts.data.products)} style="background: rgb(41, 45, 135);" class="btn text-white border-0 rounded-pill d-flex align-items-center btn-sm ps-3">Télécharger <i class="fa-regular fa-circle-down ms-2"></i></a>
															</div>
														</div>
														<div class="col">
															<div width="350px" height="220px">
																<BarChart width={350} height={220} data={that._state.items.kpiTopProducts.data.products}>
																	<Bar dataKey="ca" fill="#ffc5d0" radius={[10, 10, 0, 0]} />
																	<XAxis dataKey="product" axisLine={false} tickLine={false} tickFormatter={tickFormatter} height={80} style={{fontSize: '0.6rem' }} angle={-70} />
																	<Tooltip cursor={{ fill: "rgb(204 204 204 / 75%)" }} />
																</BarChart>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									:<div>
										<div class="spinner-border text-secondary" role="status">
											<span class="visually-hidden">Loading...</span>
										</div>
									</div>)
								:null
							}
							
							{that._state.items.kpiPerAccountingAcc?
								(that._state.items.kpiPerAccountingAcc.data?
									<div class="my-2">
										<div class="card shadow">
											<div class="card-body">
												<div>
													<div class="">
														<div class="h4 titleb">
															<img src="icons/Actions_Piloter_Activites.svg" class="d-inline-block me-1" style="width: 20px;" />
															Chiffres par comptes comptables
														</div>
													</div>
													<div>
														<div style="background: #c3c3c34a; border-radius: 2%;" class="d-flex align-items-center justify-content-center mt-3 mb-2">
															<AreaChart
																width={680}
																height={300}
																data={that._state.items.kpiPerAccountingAcc.data.products}
																margin={{
																	top: 10,
																	right: 30,
																	left: 30,
																	bottom: 0,
																}}
															>
																<XAxis dataKey="date" axisLine={false} tickLine={false} style={{fontSize: '0.8rem'}} />
																<Tooltip />
																{ that._state.items.kpiPerAccountingAcc.data.products.length > 0 ?
																	Object.keys(that._state.items.kpiPerAccountingAcc.data.products[0]).filter((e)=>e!='date').map((p, i)=>{
																		let colors = ["lightblue", "#009fe3", "rgb(0, 136, 254)", "rgb(40 46 135)"];
																		return <Area type="monotone" dataKey={p} stackId="1" stroke={colors[i]} fill={colors[i]} />
																	})
																	: <></>
																}
															</AreaChart>
														</div>
													</div>
													<div class="row mt-3 mb-3">
														<div class="col">
															<div class="mt-2 mb-4">
																<div class="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group" onChange={that.radioChanged.bind(that)}>
																	<input type="radio" class="btn-check" name="kpiPerAccountingAcc" id="fAccounting_1" autocomplete="off" value="14d" checked={that._state.items.kpiPerAccountingAcc.data.mod == '14d'} />
																	<label class="btn btn-outline-secondary px-3" for="fAccounting_1">14j</label>

																	<input type="radio" class="btn-check" name="kpiPerAccountingAcc" id="fAccounting_2" autocomplete="off" value="30d" checked={that._state.items.kpiPerAccountingAcc.data.mod == '30d'} />
																	<label class="btn btn-outline-secondary px-3" for="fAccounting_2">30j</label>

																	<input type="radio" class="btn-check" name="kpiPerAccountingAcc" id="fAccounting_3" autocomplete="off" value="90d" checked={that._state.items.kpiPerAccountingAcc.data.mod == '90d'} />
																	<label class="btn btn-outline-secondary px-3" for="fAccounting_3">90j</label>
																</div>
															</div>
															<div class="d-flex">
																<a type="button" href="#" onClick={() => this.downloadSheet("Chiffres par comptes comptables", that._state.items.kpiPerAccountingAcc.data.products)} style="background: rgb(41, 45, 135);" class="btn text-white border-0 rounded-pill d-flex align-items-center btn-sm ps-3">Télécharger <i class="fa-regular fa-circle-down ms-2"></i></a>
															</div>
														</div>
														<div class="col">
															<div class="">Voici la structure du chiffre d'affaires par compte comptable. Suivez l'importance de ventes consolidées par compte comptable et leur évolution.</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									:<div>
										<div class="spinner-border text-secondary" role="status">
											<span class="visually-hidden">Loading...</span>
										</div>
									</div>)
								:null
							}

						</div>
					</div>
				</div>
			);
			
		}

		return <div>
			<div class="spinner-border text-secondary" role="status">
				<span class="visually-hidden">Loading...</span>
			</div>
		</div>
	}
}
